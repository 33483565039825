import * as images from '../Components/AssetsImport'
import { Link } from 'react-router-dom'
import '../styles/custom.css'

export default function Contact() {
	return (
		<body>
			<div style={{ backgroundColor: '#F8F9FF' }}>
				<section id="contact">
					<div class="container">
						<div class="row">
							<div class="col-lg-12">
								<h2>Contact Us</h2>
								<div class="conatctTitle">
									<p>Any question or remarks? Just write us a message!</p>
								</div>
								<div class="contactMainBox">
									<div class="row">
										<div class="col-lg-5">
											<div class="contactInnerBox">
												<div class="conatctTitle">
													<h3>Contact Information</h3>
												</div>
												<div class="conatctTitle">
													<p class="textLeft">Say something to start a live chat!</p>
												</div>
												<div class="contactMT">
													<div class="contactFlex">
														<div>
															<img src={images.Email} />
														</div>
														<span class="contactPhone">contact@bridgeocean.digital</span>
													</div>
													<div class="mt-5 contactFlex">
														<div>
															<img src={images.Location} />
														</div>
														<span class="contactPhone">Bridgeocean Digital Limited
															71 Baggot Street
															Lower Dublin, Dublin 2
															D02P593, Ireland</span>
													</div>
												</div>
												<div class="socialMT">
													<Link to={"https://twitter.com/TPhdpush"} target="_blank"><img class="mr-3" src={images.Twitter} /></Link>
													<Link to={"https://www.linkedin.com/showcase/the-phdpush"} target="_blank"><img class="mr-3" src={images.Instagram} /></Link>
													<Link to={"https://www.facebook.com/profile.php?id=100088332137916&sk=about"} target="_blank"><img src={images.Facebook} /></Link>
												</div>
											</div>
										</div>
										<div class="col-lg-7">
											<div id="contactWrp">
												<div class="row">
													<div class="col-lg-6">
														<div class="form-group">
															<label for="exampleInputEmail1">First Name</label>
															<input type="email" class="form-control contactInput" placeholder="Enter first name" id="exampleInputEmail1" aria-describedby="emailHelp" />
														</div>
													</div>
													<div class="col-lg-6">
														<div class="form-group">
															<label for="exampleInputEmail1">Last Name</label>
															<input type="email" class="form-control contactInput" placeholder="Enter last name" id="exampleInputEmail1" aria-describedby="emailHelp" />
														</div>
													</div>
													<div class="col-lg-6 mt-2">
														<div class="form-group">
															<label for="exampleInputEmail1">Email</label>
															<input type="email" class="form-control contactInput" placeholder="Enter email" id="exampleInputEmail1" aria-describedby="emailHelp" />
														</div>
													</div>
													<div class="col-lg-6 mt-2">
														<div class="form-group">
															<label for="exampleInputEmail1">Phone</label>
															<input type="email" class="form-control contactInput" placeholder="+1012 3456 789" id="exampleInputEmail1" aria-describedby="emailHelp" />
														</div>
													</div>
													<div class="col-lg-12 mt-2">
														<div class="form-group">
															<label for="exampleFormControlTextarea1">Message</label>
															<textarea class="form-control contactTextarea" placeholder="Write your message.." id="exampleFormControlTextarea1"></textarea>
														</div>
													</div>
													<div class="col-lg-12">
														<div class="contactBTNFlex">
															<button type="button" class="btn sendMessageButton webRedColor">Send Message</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</body>

	)
}
