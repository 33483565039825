import React, { useState } from "react";
import { AppContext } from "./AppContext";

export const AppProvider = ({ children }) => {
  const [appId, setAppId] = useState(null);
  const [appName, setAppName] = useState("aaa");

  return (
    <AppContext.Provider value={{ appId, setAppId, appName, setAppName }}>
      {children}
    </AppContext.Provider>
  );
};
