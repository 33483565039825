import React, { useEffect, useState } from "react";
import PPMSChart from "../Components/PPMSChartBox";
import LogoImg from "../assets/img/logo.png";
import PlaystoreImg from "../assets/img/play-store.png";
import AppstoreImg from "../assets/img/app-store.png";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaQuora,
  FaYoutube,
  FaEnvelope,
} from "react-icons/fa6";
import { BsArrowUpRight, BsInfoCircle, BsXCircle } from "react-icons/bs";
import { Link } from "react-router-dom";

const Page2 = () => {
  const [appName, setAppName] = useState("");
  const [appId, setAppId] = useState("");
  const [store, setStore] = useState("Android");
  const [successMessage, setSuccessMessage] = useState(false);
  const [infoBox, setInfoBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const endpoint = `https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/add_app`;

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          app_id: appId,
          store: store.toLowerCase(),
          app_name: appName,
        }),
      });
      if (response.ok) {
        setSuccessMessage(true);
        setTimeout(() => {
          setSuccessMessage(false);
        }, 3000);
        // Clear the form
        setAppName("");
        setAppId("");
        setStore("Android");
        setIsLoading(false);
        setIsLoading(false);
      } else {
        console.error("Error:", await response.text());
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Network error:", error);
      setIsLoading(false);
      setIsLoading(false);
    }
  };

  return (
    <main className="bridgeocean">
      {infoBox ? (
        <div className="info-box">
          <div className="info-box-content">
            <div className="info-box-content-head">
              <h3>What's an App ID?</h3>
              <span
                onClick={() => {
                  setInfoBox(false);
                  document.body.style.overflow = "auto";
                }}
              >
                <BsXCircle />
              </span>
            </div>
            <div className="appid-box">
              <h4>For Android Apps on Play Store</h4>
              <p>
                <b>App Name field:</b> Can be any non-empty value.
                <br />
                <b>App ID field:</b> The id part from the URL in the below image
                needs to be taken and entered in the below “Search for Apps”
                search box to search for an app that you are looking for.
                <br />
                For example, in the below URL the “com.healtharx.beato” would be
                the text to enter to search for an app.
                <br />
                <b>Store Field:</b> Choose from the dropdown, “android”
              </p>
              <img src={PlaystoreImg} alt="PlaystoreImg" />
            </div>
            <div className="appid-box">
              <h4>For IOS on AppStore</h4>
              <p>
                <b>App Name field:</b> Can be any non-empty value.
                <br />
                <b>App ID field:</b> The id part from the URL in the below image
                needs to be taken and entered in the below “Search for Apps”
                search box to search for an app that you are looking for.
                <br />
                For example, in the below URL extract everything from the URL
                after the letters id which would be 1232780281
                <br />
                <b>Store Field:</b> Choose from the dropdown, “apple”
              </p>
              <img src={AppstoreImg} alt="PlaystoreImg" />
            </div>
          </div>
        </div>
      ) : null}
      <div className="page2-container">
        <div className="box">
          <form onSubmit={handleSubmit} className="page4-form">
            <h3>
              Submit Your App{" "}
              <span
                onClick={() => {
                  setInfoBox(true);
                  document.body.style.overflow = "hidden";
                }}
              >
                <BsInfoCircle />
              </span>
            </h3>
            <input
              type="text"
              placeholder="App Name"
              value={appName}
              required
              onChange={(e) => setAppName(e.target.value)}
            />
            <input
              type="text"
              placeholder="App ID"
              value={appId}
              required
              onChange={(e) => setAppId(e.target.value)}
            />
            <select
              name=""
              id=""
              value={store}
              required
              onChange={(e) => setStore(e.target.value)}
            >
              <option value="Android">Android</option>
              <option value="Apple">Apple</option>
            </select>
            <button type="submit" class="btn submitButton webRedColor">
              {isLoading ? (
                <>
                  Submitting
                  <div class="multi-ripple">
                    <div></div>
                    <div></div>
                  </div>
                </>
              ) : (
                "Submit"
              )}
            </button>
          </form>


          {successMessage ? (
            <div className="app-submission-success">
              Your App is posted successfully!
            </div>
          ) : null}
        </div>

        {/* <footer>
          <div className="box">
            <div className="footer-grid">
              <div className="footer-grid-left">
                <img src={LogoImg} alt="LogoImg" />
              </div>
              <div className="footer-grid-right">
                <a target="_blank" href="https://bridgeoceansspace.quora.com/">
                  <FaQuora />
                  Quora
                  <span>
                    <BsArrowUpRight />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@bridgeoceandigital"
                >
                  <FaYoutube />
                  Youtube
                  <span>
                    <BsArrowUpRight />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/bridgeocean-digital/"
                >
                  <FaLinkedinIn />
                  LinkedIn
                  <span>
                    <BsArrowUpRight />
                  </span>
                </a>
                <a href="mailto:contact@bridgeocean.digital">
                  <FaEnvelope />
                  contact@bridgeocean.digital
                </a>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    </main>
  );
};

export default Page2;
