import React, { useRef, useState, useEffect } from 'react';
import * as images from '../Components/AssetsImport'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { AppContext } from '../AppContext';

import '../styles/custom.css'

export default function LandingPage() {
    const [focusSection, setFocusSection] = useState(0)
    const [focusSection1, setFocusSection1] = useState(0)
    const [focusSection2, setFocusSection2] = useState(0)
    const [searchValue, setSearchValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const targetRef1 = useRef();
    const targetRef2 = useRef();
    const targetRef3 = useRef();
    useEffect(() => {
        if (targetRef1.current) {
            targetRef1.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [focusSection]);
    useEffect(() => {
        if (targetRef2.current) {
            targetRef2.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [focusSection1]);
    useEffect(() => {
        targetRef3.current.scrollIntoView({ behavior: 'smooth' });
    }, []);
    const [appsData, setAppsData] = useState(null);
    const navigate = useNavigate();
    const { appId, setAppId, appName, setAppName } = useContext(AppContext);
    const [infoBox, setInfoBox] = useState(false);
    const [PPMSdata, setPPMSdata] = useState([
        {
            model: "Enabling Factor",
            score: 70,
        },
        {
            model: "Reinforcing Factor",
            score: 30,
        },
        {
            model: "Predisposing Factor",
            score: 55,
        },
    ]);
    const randomScore = () => Math.floor(Math.random() * (101 - 50) + 50);
    useEffect(() => {
        const interval = setInterval(() => {
            setPPMSdata(
                PPMSdata.map((item) => ({
                    ...item,
                    score: randomScore(),
                }))
            );
        }, 2000);

        return () => clearInterval(interval);
    }, [PPMSdata]);

    useEffect(() => {
        fetch(
            "https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/get_all_apps"
        )
            .then((response) => response.json())
            .then((data) => setAppsData(data.apps))
            .catch((error) =>
                console.error(
                    "There was an error fetching the data of get_all_apps:",
                    error
                )
            );
    }, []);

    /* const appsData = {
      "Apollo Sugar": "com.apollo.android.sugar",
      Beato: "com.healtharx.beato",
      None: "None",
      "Teman Diabetes": "com.temandiabetes.android",
      "diabetic recipes": "com.riatech.diabeticrecipes",
      diabtrend: "com.diabtrend",
      mysugr: "com.mysugr.android.companion",
    }; */

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        setSearchValue(inputValue);

        const filteredSuggestions = Object.keys(appsData)
            .filter((app) => app.toLowerCase().includes(inputValue.toLowerCase()))
            .slice(0, 5);
        setSuggestions(filteredSuggestions);

        if (appsData[inputValue]) {
            setAppId(appsData[inputValue]);
            setAppName(inputValue);
        } else {
            setAppId(null);
            setAppName(null);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        const id = appsData[suggestion];
        setAppId(id);
        setAppName(suggestion);
        // Navigate to the app-details page
        navigate(`/app`);
    };

    const handleKeyPress = (event) => {
        const inputValue = event.target.value;
        setSearchValue(inputValue);
        if (event.key === "Enter") {
            if (appsData[inputValue]) {
                setAppId(appsData[inputValue]);
                setAppName(inputValue);
            } else {
                setAppId(null);
            }
            const foundAppId = appsData[inputValue];
            if (foundAppId) {
                setAppId(foundAppId);
                navigate(`/app`);
            }
        }
    };
    return (
        <>
            <section id="homeBanner" style={{ backgroundColor: '#F8F9FF' }}>
                <div class="container-fluid p-0">
                    <div class="row m-0" ref={targetRef3}>
                        <div class="col-lg-5 bannerColumn">
                            <h1>Bridging the Gap Between Technology and Well-Being</h1>
                            <p>At Bridgeocean Digital, we are pioneers in digital innovation, shaping the future of health,
                                technology, and education. Through cutting-edge analysis, we empower not only Healthcare
                                Providers (HCPs) in their work but also assist patients with personalized insights into their wellbeing, fostering informed decision-making and proactive health management. Our commitment
                                extends to payers, including insurance, and educators. We offer comprehensive health app
                                analyses, enhance IT services with medical chatbots, and revolutionize healthcare education.
                                This holistic approach is dedicated to creating a future of improved well-being and personal
                                branding for all stakeholders in the healthcare ecosystem.
                            </p>
                            <Link to="/form">
                                <button type="button" class="btn tryFreeButton webRedColor">Try for free</button>
                            </Link>
                        </div>
                        <div class="col-lg-7 p-0">
                            <img class="bannerImage" src={images.bannerImg} />
                        </div>
                    </div>

                </div>

            </section>
            <div className='preview-directory'>
                <body style={{ backgroundColor: 'white' }}>
                    <div style={{ backgroundColor: '#F8F9FF' }}>
                        <section id="aboutHealth" ref={targetRef2}>
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h2>Unlock Key Insights About Your Health App</h2>
                                        <div class="aboutHealthParagrapg">
                                            <p>AppWise is your gateway to unlocking valuable insights about health applications. Our platform offers advanced analysis capabilities that cover a wide range of dimensions, enabling you to make educated decisions about the apps you rely on.</p>
                                        </div>
                                        <div style={{ display: 'flex', position: 'relative' }}>
                                            <form class="health">
                                                <input type="text" placeholder="Search and Analyze Health Apps....."
                                                    value={searchValue}
                                                    onChange={handleInputChange}
                                                    onKeyPress={handleKeyPress}
                                                    autocomplete="off"
                                                    autoFocus={false}
                                                />
                                                <button type="submit">Search</button>
                                            </form>
                                            {searchValue != "" && <>
                                                {suggestions[0] ? (
                                                    <div className="preview-suggestions-box">
                                                        {suggestions.map((suggestion, index) => (
                                                            <h5
                                                                key={index}
                                                                onClick={() => handleSuggestionClick(suggestion)}
                                                            >
                                                                {suggestion}
                                                            </h5>
                                                        ))}
                                                    </div>
                                                ) : null}
                                                {suggestions.length === 0 && searchValue !== "" && (
                                                    <h6 style={{ marginTop: '110px' }}>
                                                        The app you are looking for is not available. You can{" "}
                                                        <Link to="/form">Request a New App</Link> here
                                                    </h6>
                                                )}
                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>



                        <section id="bridgeocean">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h2>Explore Bridgeocean Digital</h2>
                                    </div>
                                    <div class="col-lg-4 exploreColumn">
                                        <div class="bridgeoceanBox">
                                            <img src={images.explorer1} />
                                            <h3>Health & Professional
                                                Education
                                            </h3>
                                            <div class="exploreParagrapg">
                                                <p>Leveraging the power of analytics and predictive modeling, our service in 'Health & Professional Education' delivers invaluable insights drawn from extensive data sourced from job boards and various channels. We also furnish essential information on professional opportunities across three continents, tailored for individuals in the health and education sectors.</p>
                                            </div>
                                            <button type="submit" class="btn educateButton webRedColor" onClick={() => setFocusSection(focusSection + 1)}>Educate Me</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 exploreColumn">
                                        <div class="bridgeoceanBox">
                                            <img src={images.explorer2} />
                                            <h3>Health App Analysis</h3>
                                            <div class="exploreParagrapg">
                                                <p>AppWise is your gateway to unlocking valuable insights about health applications. We understand the importance of making informed choices when it comes to the apps you use to manage your health and well-being.</p>
                                            </div>
                                            <button type="submit" class="btn educateButton webRedColor" onClick={() => setFocusSection1(focusSection1 + 1)}>Search and Analyze Health Apps...</button>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 exploreColumn">
                                        <div class="bridgeoceanBox">
                                            <img src={images.explorer3} />
                                            <h3>IT Services</h3>
                                            <div class="exploreParagrapg">
                                                <p>We offer customized IT services at competitive prices, including the development of medical chatbots.</p>
                                            </div>
                                            <button type="submit" class="btn educateButton webRedColor" onClick={() => setFocusSection(focusSection + 1)}>I will need help with...</button>
                                        </div>
                                    </div>
                                    <div class="graphData">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <h2 class="alignLeft w60">Health App Analysis on Appwise Platform</h2>
                                                <div class="graphParapraph">
                                                    <p class="w100">
                                                        Unlock the potential of health apps with Bridgeocean Digital's Appwise platform. Our advanced analysis tools provide valuable insights into the effectiveness, usability, and features of health apps, helping users make informed decisions about their health management. Whether you're a healthcare provider, researcher, or individual seeking the best app for your needs, Appwise has you covered.</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 p-0">
                                                <img class="w100" src={images.graph1} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="graphData1">
                                        <div class="row">
                                            <div class="col-lg-6 p-0">
                                                <img class="w100" src={images.graph2} />
                                            </div>
                                            <div class="col-lg-6 graphDataSet">
                                                <h2 class="alignLeft w60">Health & Professional Education</h2>
                                                <div class="graphParapraph">
                                                    <p>At Bridgeocean Digital, we're dedicated to transforming the landscape of education and professional development. Leveraging the power of analytics and predictive modeling, our service in 'Health & Professional Education' delivers invaluable insights drawn from extensive data sourced from job boards and various channels. From comprehensive educational content to interactive learning experiences, we're here to support your journey towards success in the healthcare field. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="graphData1">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <h2 class="alignLeft w60">IT Services</h2>
                                                <div class="graphParapraph">
                                                    <p>Navigate the ever-changing landscape of technology with Bridgeocean Digital's IT Services. From software development to development of medical chatbots, we offer a comprehensive suite of services to meet your IT needs, at a competitive price.</p>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 p-0">
                                                <img class="w100" src={images.graph3} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 text-center" ref={targetRef1}>
                                        <video className="healthImage" autoPlay loop muted>
                                            <source src={images.phdVideo} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="countryBox">
                                            <img src={images.USA} />
                                        </div>
                                        <div class="countryBox">
                                            <img src={images.germany} />
                                        </div>
                                        <div class="countryBox">
                                            <img src={images.ireland} />
                                        </div>
                                        <div class="countryBox">
                                            <img src={images.country} />
                                        </div>
                                    </div>
                                    <div class="col-lg-9 watchFlex">
                                        <a href="https://www.youtube.com/@ThePhDPush" target="_blank" rel="noopener noreferrer">
                                            <img className="video" src={images.video} alt="Video Thumbnail" />
                                        </a>
                                        <h4 class="pt-5">Analytical Platform (Coming Soon)</h4>
                                        <h4>Personal Branding Clinic (Coming Soon)</h4>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </body>

            </div>
        </>

    )
}
