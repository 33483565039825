import React, { useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";

const SentimentDistributionChart = (props) => {
  const SDdata = [
    { name: "Positive  😊", value: props.positiveSentiment },
    { name: "Neutral 😐", value: props.neutralSentiment },
    { name: "Negative ☹️", value: props.negativeSentiment },
  ];

  const SDrenderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          className="sd-mobile"
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={fill}
        >
          {`${value}% ${payload.name}`}
        </text>
        <text
          className="sd-pc"
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={fill}
        >
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          className="sd-pc"
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle
          className="sd-pc"
          cx={ex}
          cy={ey}
          r={2}
          fill={fill}
          stroke="none"
        />
        <text
          className="sd-pc"
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#fff"
        >
          {`${value}% of Sentiments are ${payload.name}`}
        </text>
      </g>
    );
  };

  const [SDactiveIndex, setSDActiveIndex] = useState(0);

  const SDonPieEnter = (_, index) => {
    setSDActiveIndex(index);
  };
  const SDcolors = ["#62b58f", "#ffc533", "#f2726f"];

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Legend />
          <Pie
            activeIndex={SDactiveIndex}
            activeShape={SDrenderActiveShape}
            data={SDdata}
            cx="50%"
            cy="52.5%"
            innerRadius={80}
            outerRadius={100}
            fill={("red", "blue", "green")}
            stroke="none"
            dataKey="value"
            paddingAngle={2}
            onMouseEnter={SDonPieEnter}
            isAnimationActive={false}
          >
            {SDdata.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={SDcolors[index % SDcolors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default SentimentDistributionChart;
