import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";
import LogoImg from "../assets/img/logo.png";
import PlaystoreImg from "../assets/img/play-store.png";
import AppstoreImg from "../assets/img/app-store.png";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaQuora,
  FaYoutube,
  FaEnvelope,
} from "react-icons/fa6";
import { BsArrowUpRight, BsInfoCircle, BsXCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";

const Page2 = () => {
  const [appsData, setAppsData] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const navigate = useNavigate();
  const { appId, setAppId, appName, setAppName } = useContext(AppContext);
  const [infoBox, setInfoBox] = useState(false);
  const [PPMSdata, setPPMSdata] = useState([
    {
      model: "Enabling Factor",
      score: 70,
    },
    {
      model: "Reinforcing Factor",
      score: 30,
    },
    {
      model: "Predisposing Factor",
      score: 55,
    },
  ]);
  const randomScore = () => Math.floor(Math.random() * (101 - 50) + 50);
  useEffect(() => {
    const interval = setInterval(() => {
      setPPMSdata(
        PPMSdata.map((item) => ({
          ...item,
          score: randomScore(),
        }))
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [PPMSdata]);

  useEffect(() => {
    fetch(
      "https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/get_all_apps"
    )
      .then((response) => response.json())
      .then((data) => setAppsData(data.apps))
      .catch((error) =>
        console.error(
          "There was an error fetching the data of get_all_apps:",
          error
        )
      );
  }, []);

  /* const appsData = {
    "Apollo Sugar": "com.apollo.android.sugar",
    Beato: "com.healtharx.beato",
    None: "None",
    "Teman Diabetes": "com.temandiabetes.android",
    "diabetic recipes": "com.riatech.diabeticrecipes",
    diabtrend: "com.diabtrend",
    mysugr: "com.mysugr.android.companion",
  }; */

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);

    const filteredSuggestions = Object.keys(appsData)
      .filter((app) => app.toLowerCase().includes(inputValue.toLowerCase()))
      .slice(0, 5);
    setSuggestions(filteredSuggestions);

    if (appsData[inputValue]) {
      setAppId(appsData[inputValue]);
      setAppName(inputValue);
    } else {
      setAppId(null);
      setAppName(null);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const id = appsData[suggestion];
    setAppId(id);
    setAppName(suggestion);
    // Navigate to the app-details page
    navigate(`/app`);
  };

  const handleKeyPress = (event) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);
    if (event.key === "Enter") {
      if (appsData[inputValue]) {
        setAppId(appsData[inputValue]);
        setAppName(inputValue);
      } else {
        setAppId(null);
      }
      const foundAppId = appsData[inputValue];
      if (foundAppId) {
        setAppId(foundAppId);
        navigate(`/app`);
      }
    }
  };

  return (
    <main className="bridgeocean index-page">
      {infoBox ? (
        <div className="info-box">
          <div className="info-box-content">
            <div className="info-box-content-head">
              <h3>What's an App ID?</h3>
              <span
                onClick={() => {
                  setInfoBox(false);
                  document.body.style.overflow = "auto";
                }}
              >
                <BsXCircle />
              </span>
            </div>
            <div className="appid-box">
              <h4>For Android Apps on PlayStore</h4>
              <p>
                The id part from the URL in the below image needs to be taken
                and entered in the below “Search for Apps” search box to search
                for an app that you are looking for.
                <br />
                <br />
                For example, in the below URL the “com.healtharx.beato” would be
                the text to enter to search for an app.
              </p>
              <img src={PlaystoreImg} alt="PlaystoreImg" />
            </div>
            <div className="appid-box">
              <h4>For IOS on AppStore</h4>
              <p>
                The id part from the URL in the below image needs to be taken
                and entered in the below “Search for Apps” search box to search
                for an app that you are looking for.
                <br />
                <br />
                For example, in the below URL extract everything from the URL
                after the letters id which would be 1232780281
              </p>
              <img src={AppstoreImg} alt="PlaystoreImg" />
            </div>
          </div>
        </div>
      ) : null}
      <div className="logo-box">
        <div className="box">
          <div className="header-grid">
            <Link to="/form">
              <button>Request A New App</button>
            </Link>
          </div>
        </div>
      </div>
      <div className="page2-container">
        <div className="box">
          <div className="page2-hero-grid">
            <div className="page2-hero-grid-left">
              <h1>
                <span>Unlock Key Insights</span> About Your <i>Health App</i>
              </h1>
              <p>
                AppWise is your gateway to unlocking valuable insights about
                health applications. Our platform offers advanced analysis
                capabilities that cover a wide range of dimensions, enabling you
                to make educated decisions about the apps you rely on.
              </p>
              <div className="page2-hero-grid-input-box">
                <input
                  type="text"
                  placeholder="Search Health Apps to Analyse for Free"
                  value={searchValue}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                />
                {suggestions[0] ? (
                  <div className="suggestions-box">
                    {suggestions.map((suggestion, index) => (
                      <h5
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </h5>
                    ))}
                  </div>
                ) : null}
                {suggestions.length === 0 && searchValue !== "" && (
                  <h6>
                    The app you are looking for is not available. You can{" "}
                    <Link to="/form">Request a New App</Link> here
                  </h6>
                )}

                {/* <span
                  onClick={() => {
                    setInfoBox(true);
                    document.body.style.overflow = "hidden";
                  }}
                >
                  <BsInfoCircle />
                </span> */}
              </div>
              <Link to="/form">
                <button>Request A New App</button>
              </Link>
            </div>
            <div className="page2-hero-grid-right">
              <div className="precede-chart-box">
                <ResponsiveContainer width="100%">
                  <RadarChart
                    cx={"50%"}
                    cy={"62.5%"}
                    outerRadius={"90%"}
                    data={PPMSdata}
                  >
                    <Tooltip />
                    <Legend />
                    <PolarGrid />
                    <PolarAngleAxis dataKey="model" interval="auto" />
                    <PolarRadiusAxis />
                    <Radar
                      name="Score"
                      dataKey="score"
                      stroke="#8884d8"
                      fill="#8884d8"
                      fillOpacity={0.6}
                    />
                  </RadarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="page2-cards-grid">
            <div className="page2-card">
              <p>
                Our service provides in-depth insights on app efficacy,
                utilizing easy visualizations across three areas: clinical,
                technical, and user engagement.
              </p>
            </div>
            <div className="page2-card">
              <p>
                We provide a comprehensive analysis of the clinical, technical
                aspects, and user engagement of healthcare apps, aiding
                professionals and stakeholders in informed decision-making.
              </p>
            </div>
            <div className="page2-card">
              <p>
                Our unique blend of comprehensive data analysis and visuals
                helps stakeholders make informed decisions in a dynamic app
                development environment.
              </p>
            </div>
          </div>
        </div>

        {/* <footer>
          <div className="box">
            <div className="footer-grid">
              <div className="footer-grid-left">
                <img src={LogoImg} alt="LogoImg" />
              </div>
              <div className="footer-grid-right">
                <a target="_blank" href="https://bridgeoceansspace.quora.com/">
                  <FaQuora />
                  Quora
                  <span>
                    <BsArrowUpRight />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/@bridgeoceandigital"
                >
                  <FaYoutube />
                  Youtube
                  <span>
                    <BsArrowUpRight />
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/bridgeocean-digital/"
                >
                  <FaLinkedinIn />
                  LinkedIn
                  <span>
                    <BsArrowUpRight />
                  </span>
                </a>
                <a href="mailto:contact@bridgeocean.digital">
                  <FaEnvelope />
                  contact@bridgeocean.digital
                </a>
              </div>
            </div>
          </div>
        </footer> */}
      </div>
    </main>
  );
};

export default Page2;
