import React from "react";
import { Link } from "react-router-dom";

const Links = () => {
  return (
    <div className="links-page">
      <Link to="/app">1. App Page</Link>
      <Link to="/index">2. Index Page</Link>
      <Link to="/landing">3. Landing Page</Link>
      <Link to="/form">4. Form Page</Link>
      <Link to="/home">5. Landing Page</Link>
      <Link to="/contact">6. Conntact Us Page</Link>
      <Link to="/subscribe">6. Subscribe Page</Link>
      <Link to="/terms">7. Terms</Link>
      <Link to="/privacy">8. Privacy</Link>
      <Link to="/about">8. About Us</Link>
    </div>
  );
};

export default Links;
/* 
  const data = [
    {
      name: "Jan",
      Positive: 50,
      Neutral: 10,
      Negative: 15,
      Reviews: 25,
    },
    {
      name: "Feb",
      Positive: 65,
      Neutral: 15,
      Negative: 5,
      Reviews: 50,
    },
    {
      name: "Mar",
      Positive: 55,
      Neutral: 10,
      Negative: 5,
      Reviews: 75,
    },
    {
      name: "Apr",
      Positive: 80,
      Neutral: 5,
      Negative: 10,
      Reviews: 35,
    },
    {
      name: "May",
      Positive: 65,
      Neutral: 10,
      Negative: 20,
      Reviews: 65,
    },
    {
      name: "June",
      Positive: 40,
      Neutral: 15,
      Negative: 10,
      Reviews: 75,
    },
    {
      name: "July",
      Positive: 50,
      Neutral: 10,
      Negative: 15,
      Reviews: 25,
    },
    {
      name: "Aug",
      Positive: 65,
      Neutral: 15,
      Negative: 5,
      Reviews: 50,
    },
    {
      name: "Sep",
      Positive: 55,
      Neutral: 10,
      Negative: 5,
      Reviews: 75,
    },
    {
      name: "Oct",
      Positive: 80,
      Neutral: 5,
      Negative: 10,
      Reviews: 35,
    },
    {
      name: "Nov",
      Positive: 65,
      Neutral: 10,
      Negative: 20,
      Reviews: 65,
    },
    {
      name: "Dec",
      Positive: 40,
      Neutral: 15,
      Negative: 10,
      Reviews: 75,
    },
  ]; */

// const reviewMonths = Object.keys(data.reviews_by_month);
/* const dataA = [
          {
            name: reviewMonths[0].replace("(", "").replace(")", ""),
            Positive: data.reviews_by_month["(1, 2023)"].positive,
            Neutral: data.reviews_by_month["(1, 2023)"].neutral,
            Negative: data.reviews_by_month["(1, 2023)"].negative,
            Reviews: data.reviews_by_month["(1, 2023)"].total_reviews,
          },
          {
            name: reviewMonths[1],
            Positive: data.reviews_by_month["(2, 2023)"].positive,
            Neutral: data.reviews_by_month["(2, 2023)"].neutral,
            Negative: data.reviews_by_month["(2, 2023)"].negative,
            Reviews: data.reviews_by_month["(2, 2023)"].total_reviews,
          },
          {
            name: reviewMonths[2],
            Positive: data.reviews_by_month["(3, 2023)"].positive,
            Neutral: data.reviews_by_month["(3, 2023)"].neutral,
            Negative: data.reviews_by_month["(3, 2023)"].negative,
            Reviews: data.reviews_by_month["(3, 2023)"].total_reviews,
          },
          {
            name: reviewMonths[3],
            Positive: data.reviews_by_month["(4, 2023)"].positive,
            Neutral: data.reviews_by_month["(4, 2023)"].neutral,
            Negative: data.reviews_by_month["(4, 2023)"].negative,
            Reviews: data.reviews_by_month["(4, 2023)"].total_reviews,
          },
          {
            name: reviewMonths[4],
            Positive: data.reviews_by_month["(5, 2023)"].positive,
            Neutral: data.reviews_by_month["(5, 2023)"].neutral,
            Negative: data.reviews_by_month["(5, 2023)"].negative,
            Reviews: data.reviews_by_month["(5, 2023)"].total_reviews,
          },
          {
            name: reviewMonths[5],
            Positive: data.reviews_by_month["(6, 2023)"].positive,
            Neutral: data.reviews_by_month["(6, 2023)"].neutral,
            Negative: data.reviews_by_month["(6, 2023)"].negative,
            Reviews: data.reviews_by_month["(6, 2023)"].total_reviews,
          },
          {
            name: reviewMonths[6],
            Positive: data.reviews_by_month["(7, 2023)"].positive,
            Neutral: data.reviews_by_month["(7, 2023)"].neutral,
            Negative: data.reviews_by_month["(7, 2023)"].negative,
            Reviews: data.reviews_by_month["(7, 2023)"].total_reviews,
          },
          {
            name: reviewMonths[7],
            Positive: data.reviews_by_month["(8, 2023)"].positive,
            Neutral: data.reviews_by_month["(8, 2023)"].neutral,
            Negative: data.reviews_by_month["(8, 2023)"].negative,
            Reviews: data.reviews_by_month["(8, 2023)"].total_reviews,
          },
          {
            name: reviewMonths[8],
            Positive: data.reviews_by_month["(9, 2022)"].positive,
            Neutral: data.reviews_by_month["(9, 2022)"].neutral,
            Negative: data.reviews_by_month["(9, 2022)"].negative,
            Reviews: data.reviews_by_month["(9, 2022)"].total_reviews,
          },
          {
            name: reviewMonths[9],
            Positive: data.reviews_by_month["(10, 2022)"].positive,
            Neutral: data.reviews_by_month["(10, 2022)"].neutral,
            Negative: data.reviews_by_month["(10, 2022)"].negative,
            Reviews: data.reviews_by_month["(10, 2022)"].total_reviews,
          },
          {
            name: reviewMonths[10],
            Positive: data.reviews_by_month["(11, 2022)"].positive,
            Neutral: data.reviews_by_month["(11, 2022)"].neutral,
            Negative: data.reviews_by_month["(11, 2022)"].negative,
            Reviews: data.reviews_by_month["(11, 2022)"].total_reviews,
          },
          {
            name: reviewMonths[11],
            Positive: data.reviews_by_month["(12, 2022)"].positive,
            Neutral: data.reviews_by_month["(12, 2022)"].neutral,
            Negative: data.reviews_by_month["(12, 2022)"].negative,
            Reviews: data.reviews_by_month["(12, 2022)"].total_reviews,
          },
        ]; */
