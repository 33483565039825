import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from "recharts";

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  const text = payload.value;

  if (text.length > 15) {
    const words = text.split(" ");
    return (
      <g transform={`translate(${x},${y})`}>
        <Text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {words.slice(0, words.length / 2).join(" ")}
        </Text>
        <Text x={0} y={0} dy={30} textAnchor="middle" fill="#666">
          {words.slice(words.length / 2).join(" ")}
        </Text>
      </g>
    );
  } else {
    return (
      <g transform={`translate(${x},${y})`}>
        <Text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
          {text}
        </Text>
      </g>
    );
  }
};

const ClinicalDomainAnalysis = (props) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    fetch(
      "https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/retrieve_cd"
    )
      .then((response) => response.json())
      .then((data) => {
        const transformedData = data.data.map((item) => ({
          name: item.app_id,
          Clinical: Number(item.Clinical),
          Technical: Number(item.Technical),
          Usability: Number(item.Usability_User_Requirement),
        }));
        setChartData(transformedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={props.clinicalDomainAnalysisChartData}>
        <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Clinical" fill="#62b58f" />
        <Bar dataKey="Technical" fill="#ffc533" />
        <Bar dataKey="Usability" fill="#4989f6" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ClinicalDomainAnalysis;
