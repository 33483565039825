import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";

const SentimentAnalysisChart = (props) => {
  const SAcx = 175;
  const SAcy = 175;
  const iR = 130;
  const oR = 150;
  const value = props.value;
  const RADIAN = Math.PI / 180;
  const data = [
    { name: "1-2", value: 1, color: "#f2726f" },
    { name: "2-3", value: 1, color: "#4989f6" },
    { name: "3-4", value: 1, color: "#ffc533" },
    { name: "4-5", value: 1, color: "#62b58f" },
  ];

  const needle = (value, data, SAcx, SAcy, iR, oR, color) => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - (value - 1) / 4);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = SAcx + 5;
    const y0 = SAcy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="#none"
        fill={color}
      />,
    ];
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart
        margin={{
          top: 0,
          right: 0,
          left: -10,
          bottom: 0,
        }}
      >
        <Legend />
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={SAcx}
          cy={SAcy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
          isAnimationActive={false}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(value, data, SAcx, SAcy, iR, oR, "#fff")}
      </PieChart>
    </ResponsiveContainer>
  );
};

export default SentimentAnalysisChart;
