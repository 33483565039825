import React, { useRef, useState, useEffect } from 'react';
import '../styles/custom.css';

const SectionContent = ({ title, content, targetRef }) => (
    <div className='col-lg-12' ref={targetRef}>
        <h2>{title}</h2>
        <div className='conatctTitle'>
            <p className='conatctTitle' dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    </div>
);

export default function Terms() {
    const [focusSections, setFocusSections] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    const targetRefs = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ];

    useEffect(() => {
        targetRefs.forEach((ref, index) => {
            if (ref.current && focusSections[index] > 0) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', });
                setFocusSections((prevFocus) => {
                    const newFocus = [...prevFocus];
                    newFocus[index] = 0;
                    return newFocus;
                });
            }
        });
    }, [focusSections]);

    const sectionTitles = [
        { title: 'Acceptance of Terms', text: 'Welcome to Bridgeocean Digital, an innovative platform where we seamlessly blend education, health, and technology to foster positive change. At Bridgeocean Digital, our mission is to bridge the gap between human well-being and cutting-edge technology, empowering individuals to lead healthier lives while fostering an environment for continuous learning.' },
        {
            title: 'Use of the Site', text: `<span style={{ fontWeight: '700' }}>2.1 License: </span> <br /> Bridgeocean Digital grants you a limited, non-exclusive, revocable license to access and use the Site for your personal or commercial use. <br /><br />
        <span style={{ fontWeight: '700' }}>2.2 Restrictions: </span> <br /> You may not reproduce, modify, distribute, display, or otherwise exploit any content on the Site without the prior written consent of Bridgeocean Digital.` },
        {
            title: 'User Accounts', text: `<span style={{ fontWeight: '700' }}>3.1 Account Creation: </span> <br /> To access certain features of the Site, you may be required to create an account or sign up. You agree to provide accurate, current, and complete information during the registration process. <br /><br />
    <span style={{ fontWeight: '700' }}>3.2 Account Security:</span> <br /> You are responsible for maintaining the confidentiality of your account information, and you are fully responsible for all activities that occur under your account.`},
        {
            title: 'Privacy Policy', text: `Your use of the Site is also governed by our Privacy Policy. Please review our Privacy Policy`
        },
        {
            title: 'Intellectual Property', text: `<span style={{ fontWeight: '700' }}>5.1 Trademarks:</span> <br /> All trademarks, service marks, graphics, and logos used in connection with the Site are the trademarks of Bridgeocean Digital or its licensors.<br /><br />
            <span style={{ fontWeight: '700' }}>5.2 Copyright: </span> <br /> All content on the Site is protected by copyright laws. You may not use, modify, or reproduce any content without the express written consent of Bridgeocean Digital.<br /><br />
`},
        {
            title: 'Disclaimer of Warranties', text: `The Site is provided "as is" without any warranties, expressed or implied. Bridgeocean Digital makes no representations or warranties in relation to the Site or the information and materials provided on the Site.`
        },
        {
            title: 'Limitation of Liability', text: `To the extent permitted by law, Bridgeocean Digital shall not be liable for any indirect, consequential, exemplary, or incidental damages arising from or in connection with the use of the Site.`
        },
        {
            title: 'Termination', text: `Bridgeocean Digital may terminate or suspend your account and access to the Site immediately, without prior notice or liability, for any reason whatsoever.`
        },
        {
            title: 'Governing Law', text: `These Terms are governed by and construed in accordance with the laws of Ireland, and you submit to the exclusive jurisdiction of the state and federal courts located in Ireland for the resolution of any disputes.`
        },
        {
            title: 'Changes to Terms', text: `Bridgeocean Digital reserves the right to modify or replace these Terms at any time. It is your responsibility to review these Terms periodically for changes.`
        },
        {
            title: 'Contact Information', text: `If you have any questions about these Terms, please contact us at contact@bridgeocean.digital`
        },
    ];

    return (
        <body>
            <div style={{ backgroundColor: '#F8F9FF' }}>
                <section id='contact'>
                    <div className='container'>
                        <div className='row terms'>
                            <div className='col-lg-12 page-title'>
                                <h1>Privacy Policy</h1>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='conatctTitle col-lg-12'>
                                            <p className='title'>Privacy Policy</p>
                                        </div>
                                        {sectionTitles.map((title, index) => (
                                            <div key={index} className='conatctTitle col-lg-12'>
                                                <p
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        setFocusSections((prevFocus) => {
                                                            const newFocus = [...prevFocus];
                                                            newFocus[index] += 1;
                                                            return newFocus;
                                                        })
                                                    }
                                                >
                                                    {title.title}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8'>
                                <div className='row'>
                                    {sectionTitles.map((title, index) => (
                                        <SectionContent
                                            key={index}
                                            title={`${index + 1}. ${title.title}`}
                                            content={title.text}
                                            targetRef={targetRefs[index]}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </body>
    );
}
