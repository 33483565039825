import React from "react";
import "./styles/globals.css";
import {
  BrowserRouter as Switch,
  Routes,
  Route,
  Router,
  useLocation,
} from "react-router-dom";
import Page1 from "./views/Page1";
import Page2 from "./views/Page2";
import Page3 from "./views/Page3";
import Page4 from "./views/Page4";
import LandingPage from "./views/LandingPage";
import Contact from "./views/Contact";
import Links from "./views/Links";
import { AppProvider } from "./AppProvider";
import ScrollToTop from "./ScrollToTopOnNav";
import Subscribe from "./views/Subsceibe";
import Terms from "./views/Terms";
import Privacy from "./views/Privacy";
import About from "./views/About";
import Footer from "./views/Footer";
import Header from "./views/Header";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.slim.min.js';
import 'popper.js/dist/umd/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

const App = () => {
  return (
    <Switch>
      <AppProvider>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route exact path="/app" element={<Page1 />} />
          <Route exact path="/index" element={<Page2 />} />
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/form" element={<Page4 />} />
          <Route exact path="/home" element={<LandingPage />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/subscribe" element={<Subscribe />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/about" element={<About />} />
        </Routes>
        <Footer />
      </AppProvider>
    </Switch>
  );
};

export default App;
