import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ComposedChart,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";

const SentimentTimelineChart = (props) => {
  const [chartData, setChartData] = useState([]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={props.sentimentTimelineChartData}
        margin={{
          top: 0,
          right: 10,
          left: 10, // Adjust the left margin to give space for the label
          bottom: 0,
        }}
      >
        <XAxis dataKey="name" stroke="#fff" />
        <YAxis
          yAxisId="left"
          orientation="left"
          stroke="#fff"
          domain={[0, 100]}
          tickFormatter={(tick) => Math.round(tick)}
        >
          <Label value="Sentiment" angle={-90} position="outside" dx={-15} />
        </YAxis>
        <YAxis
          yAxisId="right"
          orientation="right"
          stroke="#4989f6"
          strokeWidth={2}
        >
          <Label value="Reviews" angle={-90} position="outside" dx={15} />
        </YAxis>
        <Tooltip />
        <Legend />
        <Bar yAxisId="left" dataKey="Positive" stackId="stack" fill="#62b58f" />
        <Bar yAxisId="left" dataKey="Neutral" stackId="stack" fill="#ffc533" />
        <Bar yAxisId="left" dataKey="Negative" stackId="stack" fill="#f2726f" />
        <Line
          yAxisId="right"
          type="monotone"
          dataKey="Reviews"
          strokeWidth={3}
          stroke="#4989f6"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default SentimentTimelineChart;
