import React, { useState, useEffect, useContext } from "react";
import SentimentTimelineChart from "../Components/SentimentTimelineChartBox";
import PPMSChart from "../Components/PPMSChartBox";
import LogoImg from "../assets/img/logo.png";
import { AppContext } from "../AppContext";
import {
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaQuora,
  FaYoutube,
  FaEnvelope,
} from "react-icons/fa6";
import { BsArrowUpRight, BsLock } from "react-icons/bs";
import SentimentAnalysisChart from "../Components/SentimentAnalysisChartBox";
import SentimentDistributionChart from "../Components/SentimentDistributionChartBox";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import ClinicalDomainAnalysis from "../Components/ClinicalDomainAnalysis";

const Page1 = () => {
  const { appId, appName } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [sentimentValue, setSentimentValue] = useState(1);
  const [positiveSentiment, setPositiveSentiment] = useState(1);
  const [neutralSentiment, setNeautralSentiment] = useState(1);
  const [negativeSentiment, setNegativeSentiment] = useState(1);
  const [enablingFactor, setEnablingFactor] = useState([]);
  const [enablingFactorDecision, setEnablingFactorDecision] = useState();
  const [preDisposingFactor, setPreDisposingFactor] = useState([]);
  const [preDisposingFactorDecision, setPreDisposingFactorDecision] =
    useState();
  const [reinforcementFactor, setReinforcementFactor] = useState([]);
  const [reinforcementFactorDecision, setReinforcementFactorDecision] =
    useState();
  const [enablingFactorScore, setEnablingFactorScore] = useState();
  const [preDisposingFactorScore, setPreDisposingFactorScore] = useState();
  const [reinforcementFactorScore, setReinforcementFactorScore] = useState();
  const [sentimentTimelineChartData, setSentimentTimelineChartData] = useState(
    []
  );
  const [showSkewedCautionMsg, setShowSkewedCautionMsg] = useState(false);
  const [clinicalDomainAnalysisChartData, setClinicalDomainAnalysisChartData] =
    useState([]);
  const [specialAppsLookup, setSpecialAppsLookup] = useState({});

  useEffect(() => {
    const urls = [
      "https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/sentiment?app_id=com.healtharx.beato",
      "https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/retrieve_cd",
      "https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/preceed_proceed?app_id=com.healtharx.beato",
    ];

    urls.forEach((url) => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.text(); // or response.json() if the server responds with JSON
        })
        .then((data) => {
          // Here, you could potentially log the data if you wanted to check it
          // But since you mentioned you don't care about the response, you could also just do nothing here
          console.log(data);
        })
        .catch((error) => {
          // Handle errors if you want to, or you could potentially ignore them
          console.error("There was a problem with the fetch operation:", error);
        });
    });
  }, []);

  useEffect(() => {
    if (appId) {
      Promise.all([
        fetch(
          `https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/sentiment?app_id=${appId}`
        )
          .then((response) => response.json())
          .then((data) => {
            setSentimentValue(data.sentiment_score);
            setPositiveSentiment(data.review_percentage.positive);
            setNeautralSentiment(data.review_percentage.neutral);
            setNegativeSentiment(data.review_percentage.negative);

            const reviewMonths = data.reviews_by_month;
            const sentimentTimelineChart = reviewMonths.map((review) => {
              const month = review[0];
              const reviewData = review[1];
              return {
                name: month[0] + " " + month[1],
                Positive: Math.round(reviewData.positive),
                Neutral: Math.round(reviewData.neutral),
                Negative: Math.round(reviewData.negative),
                Reviews: reviewData.total_reviews,
              };
            });
            setSentimentTimelineChartData(sentimentTimelineChart);
            console.log(sentimentTimelineChart);
            const objectLength = Object.keys(sentimentTimelineChart).length;
            if (objectLength > 7) {
              setShowSkewedCautionMsg(false);
            } else {
              setShowSkewedCautionMsg(true);
            }
          }),
        fetch(
          `https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/preceed_proceed?app_id=${appId}`
        )
          .then((response) => response.json())
          .then((data) => {
            const enablingFactor =
              data["Precede-Proceed-Analysis"]["enabling_factor"];
            setEnablingFactor(enablingFactor);
            const preDisposingFactor =
              data["Precede-Proceed-Analysis"]["predisposing_factor"];
            setPreDisposingFactor(preDisposingFactor);
            const reinforcementFactor =
              data["Precede-Proceed-Analysis"]["reinforcing_factor"];
            setReinforcementFactor(reinforcementFactor);
            setEnablingFactorDecision(
              data["Precede-Proceed-Analysis"]["enabling_factor"].decision
            );
            setPreDisposingFactorDecision(
              data["Precede-Proceed-Analysis"]["predisposing_factor"].decision
            );
            setReinforcementFactorDecision(
              data["Precede-Proceed-Analysis"]["reinforcing_factor"].decision
            );
            setEnablingFactorScore(
              data["Precede-Proceed-Analysis"][
                "enabling_factor"
              ].overall_score.replace("%", "")
            );
            setPreDisposingFactorScore(
              data["Precede-Proceed-Analysis"][
                "predisposing_factor"
              ].overall_score.replace("%", "")
            );
            setReinforcementFactorScore(
              data["Precede-Proceed-Analysis"][
                "reinforcing_factor"
              ].overall_score.replace("%", "")
            );
          }),
        fetch(
          "https://slvw3r50pk.execute-api.eu-west-1.amazonaws.com/dev/retrieve_cd"
        )
          .then((response) => response.json())
          .then((data) => {
            const transformedData = data.data.map((item) => ({
              name: item.app_name,
              app_id: item.app_id,
              Clinical: Number(item.Clinical),
              Technical: Number(item.Technical),
              Usability: Number(item.Usability_User_Requirement),
            }));

            const sortedData = transformedData.sort((a, b) => {
              if (a.app_id === appId) return -1;
              if (b.app_id === appId) return 1;
              return 0;
            });

            setClinicalDomainAnalysisChartData(sortedData);
            const lookup = {};
            transformedData.forEach((app) => {
              lookup[app.app_id] = app;
            });
            setSpecialAppsLookup(lookup);
          }),
      ])
        .then(() => {
          // Data from both fetches has been processed
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
  }, [appId]);

  let color;
  let backgroundColor;
  let sentiment;
  if (sentimentValue >= 1 && sentimentValue <= 2) {
    color = "#f2726f";
    backgroundColor = "rgba(242, 113, 111, 0.15)";
    sentiment = "Poor";
  } else if (sentimentValue > 2 && sentimentValue <= 3) {
    color = "#4989f6";
    backgroundColor = "rgba(73, 137, 246, 0.15)";
    sentiment = "Caution";
  } else if (sentimentValue > 3 && sentimentValue <= 4) {
    color = "#ffc533";
    backgroundColor = "rgba(255, 197, 51, 0.15)";
    sentiment = "Neutral";
  } else if (sentimentValue > 4 && sentimentValue <= 5) {
    color = "#62b58f";
    backgroundColor = "rgba(98, 181, 144, 0.15)";
    sentiment = "Greed";
  }
  function formatKey(key) {
    return key
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <main className="bridgeocean">
      {isLoading ? (
        <div className="loading-screen">
          Loading
          <div class="multi-ripple">
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <>
          <div className="logo-box">
            <div className="box">
            </div>
          </div>
          <div className="page1-container">
            <div className="box">
              <div className="glass-box gb1">
                <h2>{appName}</h2>
                <br />
                <h3>Precede-Proceed Model Summary</h3>
                <div className="precede-chart-box">
                  <PPMSChart
                    enablingFactorScore={enablingFactorScore}
                    reinforcementFactorScore={reinforcementFactorScore}
                    preDisposingFactorScore={preDisposingFactorScore}
                  />
                </div>
                <div className="ppms-details-box ppmsdb1">
                  <h3>Enabling Factor</h3>
                  <p>
                    An "enabling factor," as per the PRECEDE-PROCEED model,
                    refers to the conditions or resources that facilitate a
                    behavior or make it possible. They can be skills, resources,
                    or services, and might include availability and
                    accessibility of resources, or services that facilitate the
                    action.
                  </p>
                  <h4>
                    Does this app satisfy the "Enabling Factor"? :{" "}
                    <span>{enablingFactorDecision}</span>
                  </h4>
                  <table>
                    <thead>
                      <tr>
                        <th>Enabling Factor</th>
                        <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(enablingFactor)
                        .filter(([key]) => key !== "decision")
                        .map(([key, value]) => (
                          <tr key={key}>
                            <td>{formatKey(key)}</td>
                            <td>{value}</td>
                          </tr>
                        ))}

                      {/* <tr>
                    <td>Providing a Service</td>
                    <td>90%</td>
                  </tr>
                  <tr>
                    <td>Teaching a Skill</td>
                    <td>80%</td>
                  </tr>
                  <tr>
                    <td>Tracking Progress/Recording Behavior</td>
                    <td>90%</td>
                  </tr> */}
                    </tbody>
                  </table>
                </div>
                <div className="ppms-details-box ppmsdb2">
                  <h3>Predisposing Factor</h3>
                  <p>
                    Predisposing Factors: These are the factors that affect an
                    individual's motivation or readiness to change a behavior.
                    They include an individual's knowledge, beliefs, values, and
                    attitudes. For example, a person's belief about the benefits
                    of physical activity or their knowledge about the risks of
                    unhealthy diet can predispose them to engage in physical
                    activity or to eat healthily.
                  </p>
                  <h4>
                    Does this app satisfy the "Predisposing Factor"? :{" "}
                    <span>{preDisposingFactorDecision}</span>
                  </h4>
                  <table>
                    <thead>
                      <tr>
                        <th>Predisposing Factor</th>
                        <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(preDisposingFactor)
                        .filter(([key]) => key !== "decision")
                        .map(([key, value]) => (
                          <tr key={key}>
                            <td>{formatKey(key)}</td>
                            <td>{value}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="ppms-details-box">
                  <h3>Reinforcing Factor</h3>
                  <p>
                    Reinforcing Factors: These are factors that reward or
                    reinforce a behavior after it has taken place, thereby
                    making it more likely to continue in the future. They can be
                    social (like positive feedback, support or recognition from
                    peers, family, or healthcare providers), or can also include
                    personal experiences such as the perceived benefits of the
                    behavior. For example, a person may continue to engage in
                    regular physical activity if they receive positive feedback
                    from their doctor about improved health outcomes, or if they
                    personally perceive benefits such as feeling more energetic
                    or less stressed.
                  </p>
                  <h4>
                    Does this app satisfy the "Reinforcement Factor"? :{" "}
                    <span>{reinforcementFactorDecision}</span>
                  </h4>
                  <table>
                    <thead>
                      <tr>
                        <th>Reinforcement Factor</th>
                        <th>Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(reinforcementFactor)
                        .filter(([key]) => key !== "decision")
                        .map(([key, value]) => (
                          <tr key={key}>
                            <td>{formatKey(key)}</td>
                            <td>{value}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="glass-box gb2">
                <h3>Sentiment Analysis</h3>
                <div className="sl-grid">
                  <div className="sentiment-chart-box">
                    <SentimentAnalysisChart value={sentimentValue} />
                  </div>
                  <div
                    style={{ backgroundColor }}
                    className="sentiment-level-value-box"
                  >
                    <h3>{sentimentValue} / 5</h3>
                    <h1 style={{ color }}> {sentiment}</h1>
                  </div>
                </div>
                <h3>Sentiment Distribution</h3>
                <div className="sd-grid">
                  <div className="sentiment-distribution-chart-box">
                    <SentimentDistributionChart
                      positiveSentiment={positiveSentiment}
                      neutralSentiment={neutralSentiment}
                      negativeSentiment={negativeSentiment}
                    />
                  </div>
                </div>
                <h3>Sentiment Timeline</h3>
                {showSkewedCautionMsg ? (
                  <p>
                    The graph may be skewed because we have very few data points
                  </p>
                ) : null}
                <div className="sentiment-timeline-chart-box">
                  <SentimentTimelineChart
                    sentimentTimelineChartData={sentimentTimelineChartData}
                  />
                </div>
              </div>
              {specialAppsLookup[appId] ? (
                <div className="glass-box gb3">
                  {/* <div className="glass-box-locked-box">
                <p>
                  <BsLock /> This is Locked
                </p>
                <button>Subscribe to View Data</button>
              </div> */}

                  <h3>Domain Analysis</h3>
                  <p>
                    The bar chart visually compares the performance of health
                    apps across three key dimensions: Clinical, Technical, and
                    Usability. Higher bars indicate stronger performance in each
                    respective domain
                  </p>
                  <div className="clinical-domain-analysis-box">
                    <ClinicalDomainAnalysis
                      clinicalDomainAnalysisChartData={
                        clinicalDomainAnalysisChartData
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="glass-box gb3">
                  <div className="glass-box-locked-box">
                    <p>
                      <BsLock /> This is Locked
                    </p>
                    <Link type="button" to={"/subscribe"} class="btn tryFreeButton webRedColor">Subscribe to View Data</Link>
                  </div>
                  <div className="glass-box-locked">
                    <h3>Domain Analysis</h3>
                    <p>
                      The bar chart visually compares the performance of health
                      apps across three key dimensions: Clinical, Technical, and
                      Usability. Higher bars indicate stronger performance in
                      each respective domain
                    </p>
                    <div className="clinical-domain-analysis-box">
                      <ClinicalDomainAnalysis
                        clinicalDomainAnalysisChartData={
                          clinicalDomainAnalysisChartData
                        }
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* <footer>
              <div className="box">
                <div className="footer-grid">
                  <div className="footer-grid-left">
                    <img src={LogoImg} alt="LogoImg" />
                  </div>
                  <div className="footer-grid-right">
                    <a
                      target="_blank"
                      href="https://bridgeoceansspace.quora.com/"
                    >
                      <FaQuora />
                      Quora
                      <span>
                        <BsArrowUpRight />
                      </span>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@bridgeoceandigital"
                    >
                      <FaYoutube />
                      Youtube
                      <span>
                        <BsArrowUpRight />
                      </span>
                    </a>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/bridgeocean-digital/"
                    >
                      <FaLinkedinIn />
                      LinkedIn
                      <span>
                        <BsArrowUpRight />
                      </span>
                    </a>
                    <a href="mailto:contact@bridgeocean.digital">
                      <FaEnvelope />
                      contact@bridgeocean.digital
                    </a>
                  </div>
                </div>
              </div>
            </footer> */}
          </div>
        </>
      )}
    </main>
  );
};

export default Page1;
