import React, { useRef, useState, useEffect } from 'react';
import '../styles/custom.css';

const SectionContent = ({ title, content, targetRef }) => (
    <div className='col-lg-12' ref={targetRef}>
        <h2>{title}</h2>
        <div className='conatctTitle'>
            <p className='conatctTitle' dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    </div>
);

export default function About() {
    const [focusSections, setFocusSections] = useState([0, 0, 0, 0, 0]);

    const targetRefs = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ];

    useEffect(() => {
        targetRefs.forEach((ref, index) => {
            if (ref.current && focusSections[index] > 0) {
                ref.current.scrollIntoView({ behavior: 'smooth',  block: 'center', });
                setFocusSections((prevFocus) => {
                    const newFocus = [...prevFocus];
                    newFocus[index] = 0;
                    return newFocus;
                });
            }
        });
    }, [focusSections]);

    const sectionTitles = [
        { title: 'About Bridgeocean Digital', text: 'Welcome to Bridgeocean Digital, an innovative platform where we seamlessly blend education, health, and technology to foster positive change. At Bridgeocean Digital, our mission is to bridge the gap between human well-being and cutting-edge technology, empowering individuals to lead healthier lives while fostering an environment for continuous learning.' },
        { title: 'Our Vision', text: `At Bridgeocean Digital, we envisage a world where technology not only transforms education and health but also becomes a powerful tool for personal brand management. We believe in the profound impact of digital solutions on individuals and communities, and our vision extends to enhancing not only physical and mental health but also your digital presence and personal brand.` },
        {
            title: 'Our Mission', text: `<span style={{ fontWeight: '700' }}>Transforming Education:</span> <br /> We are passionate about reshaping the educational landscape. Through our innovative digital solutions, we strive to make learning accessible, engaging, and tailored to individual needs. From personalized learning experiences to advanced educational analytics, we are committed to revolutionizing the way people acquire knowledge.<br /><br />
    <span style={{ fontWeight: '700' }}>Promoting Health and Well-being:</span> <br /> Health is wealth, and we are dedicated to enriching lives through digital health solutions. From wellness apps to advanced health analytics, including the analysis of mobile health apps, we leverage technology to empower individuals to take control of their health journey. Our goal is to create a world where everyone has the tools they need to live their healthiest lives.<br /><br />
    <span style={{ fontWeight: '700' }}>Driving Technological Innovation:</span> <br /> In the ever-evolving digital landscape, we embrace the spirit of innovation. Bridgeocean Digital is at the forefront of technological advancements, exploring new possibilities, pushing boundaries, and offering cutting-edge services such as medical chatbots. We believe in harnessing the power of technology to create solutions that make a meaningful impact on individuals and society.<br /><br />
    <span style={{ fontWeight: '700' }}>Brand Analytics and Personal Brand Management:</span> <br /> In addition to education and health, we recognize the importance of personal brand management in today's interconnected world. Our brand analytics services are designed to empower individuals, businesses, and professionals with insights into their digital presence. We understand the significance of cultivating and managing a strong personal brand, and our solutions are crafted to support your journey in the digital realm.<br />`},
        {
            title: 'What Sets Us Apart', text: `<span style={{ fontWeight: '700' }}>Holistic Approach:</span> <br /> We understand the interconnectedness of education, health, technology, and personal brand management. Our holistic approach allows us to create integrated solutions that address the diverse needs of our users.<br /><br />
    <span style={{ fontWeight: '700' }}>User-Centric Design: </span> <br /> The user is at the heart of everything we do. Our solutions are designed with the end-user in mind, ensuring a seamless and enjoyable experience.<br /><br />
    <span style={{ fontWeight: '700' }}>Innovation as a Guiding Principle:</span> <br /> Innovation is not just a buzzword for us; it's a way of life. We continuously seek new and better ways to enhance the lives of our users.<br /><br />`},
        {
            title: 'Join Us on the Journey', text: `Whether you're a learner, a health enthusiast, a tech aficionado, or someone navigating the intricacies of personal brand management, we invite you to join us on this exciting journey. Explore our range of services and check out our YouTube Channel @ThePhDPush and discover how Bridgeocean Digital is shaping the future of education, health, technology, and personal brand empowerment.
   Welcome to Bridgeocean Digital – Where Innovation Meets Impact.`},
    ];

    return (
        <body>
            <div style={{ backgroundColor: '#F8F9FF' }}>
                <section id='contact'>
                    <div className='container'>
                        <div className='row terms'>
                            <div className='col-lg-12 page-title'>
                                <h1>About Us</h1>
                            </div>
                            <div className='col-lg-4'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='conatctTitle col-lg-12'>
                                            <p className='title'>About Us</p>
                                        </div>
                                        {sectionTitles.map((title, index) => (
                                            <div key={index} className='conatctTitle col-lg-12'>
                                                <p
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() =>
                                                        setFocusSections((prevFocus) => {
                                                            const newFocus = [...prevFocus];
                                                            newFocus[index] += 1;
                                                            return newFocus;
                                                        })
                                                    }
                                                >
                                                    {title.title}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-8'>
                                <div className='row'>
                                    {sectionTitles.map((title, index) => (
                                        <SectionContent
                                            key={index}
                                            title={`${index + 1}. ${title.title}`}
                                            content={title.text}
                                            targetRef={targetRefs[index]}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </body>
    );
}
