import React, { useState, useEffect } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Sector,
  Cell,
} from "recharts";
const PPMSChart = (props) => {
  const PPMSdata = [
    {
      model: "Enabling Factor",
      score: props.enablingFactorScore,
    },
    {
      model: "Reinforcing Factor",
      score: props.reinforcementFactorScore,
    },
    {
      model: "Predisposing Factor",
      score: props.preDisposingFactorScore,
    },
  ];

  return (
    <ResponsiveContainer width="100%">
      <RadarChart cx={"50%"} cy={"62.5%"} outerRadius={"90%"} data={PPMSdata}>
        <Tooltip />
        <Legend />
        <PolarGrid />
        <PolarAngleAxis dataKey="model" interval="auto" />
        <PolarRadiusAxis />
        <Radar
          name="Score"
          dataKey="score"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.6}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default PPMSChart;
