import React from 'react'
import { Link } from 'react-router-dom'
import * as images from '../Components/AssetsImport'

import '../styles/custom.css'

export default function Header() {
    return (
        <section id="navbar-inner" class="webDarkBlue">
            <div class="container">
                <div class="row">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div className='sd-pc'>
                            <Link class="sd-pc nav-link" to={'/home'}><img src={images.logo} /></Link>
                        </div>
                        <button class="sd-pc navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="sd-pc collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item ">
                                    <Link class="nav-link" to={'/'}>Home <span class="sr-only">(current)</span></Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to={"/about"}>About Us</Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" to={"/contact"}>Contact Us</Link>
                                </li>
                            </ul>
                            <Link to={'/subscribe'} class="btn navSignUp">Subscribe</Link>
                        </div>


                        <div className='sd-mobile'>
                            <div class=" navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav ml-auto">
                                    <li class="nav-item ">
                                        <Link class="nav-link" to={'/'}>Home <span class="sr-only">(current)</span></Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link class="nav-link" to={"/about"}>About Us</Link>
                                    </li>
                                    <li class="nav-item">
                                        <Link class="nav-link" to={"/contact"}>Contact Us</Link>
                                    </li>
                                </ul>
                                <Link to={'/subscribe'} class="btn navSignUp" style={{ paddingTop: '7px' }}>Subscribe</Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </section>
    )
}
