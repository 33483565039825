import React from 'react'
import { Link } from 'react-router-dom'
import * as images from '../Components/AssetsImport'

import '../styles/custom.css'
export default function Footer() {
    return (
        <>
            <div className='sd-pc'>
                <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="footerTop"></div>
                                <div class="footerFlex">
                                    <div class="footerTitle">
                                        <p>All rights Reserved © BridgeOcean Digital, 2023</p>
                                    </div>
                                    <div class="footerTermMargin">
                                        <Link to={'/terms'}><span class="footerTerm">Terms & Conditions</span></Link>
                                        <Link to={'/privacy'}><span class="footerTerm">Privacy Policy</span></Link>
                                    </div>
                                    <div class="footerTermMargin">
                                        <Link to={"https://www.facebook.com/profile.php?id=100088332137916&sk=about"} target="_blank"><img class="mr-4" src={images.Facebook} /></Link>
                                        <Link to={"https://twitter.com/TPhdpush"} target="_blank"><img class="mr-4" src={images.Twitter} /></Link>
                                        <Link to={"https://www.linkedin.com/showcase/the-phdpush"} target="_blank"><img src={images.Instagram} /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <div className='sd-mobile'>
                <footer>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="footerTop"></div>
                          
                                    <div class="footerTermMargin">
                                    <Link to={'/terms'}><span class="footerTerm">Terms & Conditions</span></Link>
                                        <Link to={'/privacy'}><span class="footerTerm">Privacy Policy</span></Link>
                                    
                                        <Link to={"https://www.facebook.com/profile.php?id=100088332137916&sk=about"}><img class="mr-4" src={images.Facebook} /></Link>
                                        <Link to={"https://twitter.com/TPhdpush"} target="_blank"><img class="mr-4" src={images.Twitter} /></Link>
                                        <Link to={"https://www.linkedin.com/showcase/the-phdpush"} target="_blank"><img src={images.Instagram} /></Link>
                                    </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="footerFlex">
                                    <div class="footerTitle">
                                        <p>All rights Reserved © BridgeOcean Digital, 2023</p>
                                    </div>                              
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>


    )
}
